export const yesOrNotSelectPT = [
	{ label: "Sim", value: true },
	{ label: "Não", value: false }
];

export const paymentTypePT = [
	{ label: "Pronto pagamento", value: 1 },
	{ label: "Financiamento Total", value: 2 },
	{ label: "Financiamento Parcial", value: 3 }
];
