import { useContext, useState } from "react";

import SellContract from "@views/SellContract/SellContract";
import { UserContext } from "@contexts/UserContext";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import {
	getFilterDealershipsLocals,
	getFilterGestorias,
	getFilterProvinces,
} from "@services/Filtros/filtros";
import { errorToast, successToast } from "@helpers/toastFunctions";
import {
	insouranceOptions,
	ivaTypeOptions,
	transportDeliveryTypeOptions,
	yesOrNotSelect
} from "@constants/selectOptions";
import { SELL_CONTRACT_DEFAULT_PRICES } from "@constants/sellContratDefaults";
import { getWarrantiesOptions } from "@services/Warranties/warranties";

const CreateSellContract = ({ data, onCreate }) => {
	const { user: employeeId } = useContext(UserContext);

	const [dealershipsOptions, setDealershipsOptions] = useState([]);
	const [dealershipCompanie, setDealershipCompanie] = useState({});
	const [gestoriasOptions, setGestoriasOptions] = useState([]);
	const [provincesOptions, setProvincesOptions] = useState([]);
	const [warrantyOptions, setWarrantyOptions] = useState([]);

	const getCompanieByLocal = async () => {
		try {
			const response = await instance.post(API.GET_EMPRESAS_BY_LOCAL, {
				id_local: data.lead.id_local_for_marketplace
			});
			const companie = response.data[0];
			setDealershipCompanie(companie);
			return companie;
		} catch (err) {
			errorToast(err?.response?.data?.message || "Error al obtener la empresa del local");
		}
	};
	const loadDefaultValues = async () => {
		const [dealerships, dealershipCompanie, gestorias, provinces, warranties] = await Promise.all([
			getFilterDealershipsLocals(),
			getCompanieByLocal(),
			getFilterGestorias(),
			getFilterProvinces(),
			getWarrantiesOptions(data.lead.vehicle_id)
		]);

		setDealershipsOptions(dealerships);
		setGestoriasOptions(gestorias);
		setProvincesOptions(provinces);
		setWarrantyOptions(warranties);

		const clientDefault = {
			name: data.reservation?.nombre || data.client.name,
			firstSurname: data.reservation?.apellido1 || data.client.firstSurname,
			secondSurname: data.reservation?.apellido2 || data.client.secondSurname,
			phone: data.reservation?.telefono || data.client.contact.primaryPhone,
			email: data.reservation?.email || data.client.contact.email,
			dni: data.reservation?.dni,
			province: provinces.find((province) => province.value === data.client.address?.province?.id),
			town: data.client.address?.municipality,
			address: "",
			cp: data.client.address?.zipCode,
			dateOfBirth: data.client.birthday
		};

		const vehicleDefault = {
			plate: data.lead.matricula,
			km: data.lead.kilometros.replaceAll(".", ""),
			bastidor: data.lead.bastidor,
			dealership: dealerships.find((dealership) => dealership.label === data.lead.nombre_local),
			dealershipProvince: undefined,
			companie: dealershipCompanie.label,
			iva: data.lead.aplica_iva ? ivaTypeOptions[1] : ivaTypeOptions[0],
			dateLastITV: null,
			dateExpirationITV: null,
			conformidades: null
		};

		const sellInfoDefault = {
			priceType: undefined,
			pvp: 0,
			ownershipChangeAmount: SELL_CONTRACT_DEFAULT_PRICES.OWNERSHIP_AMOUNT,
			gestoria: gestorias.find((gestoria) => gestoria.value === 1),
			courtesy:0,
			financing: {
				wants: undefined,
				discount: data.lead.pvp_contado - data.lead.pvp_financiado,
				financialEntity: undefined,
				TIN: SELL_CONTRACT_DEFAULT_PRICES.TIN,
				term: undefined,
				quota: SELL_CONTRACT_DEFAULT_PRICES.QUOTA
			},
			transport: {
				sellType: undefined,
				wants: undefined,
				delivery: {
					amount: SELL_CONTRACT_DEFAULT_PRICES.TRANSPORT_AMOUNT,
					type: transportDeliveryTypeOptions[0],
					dealership: { label: data.lead.nombre_local, value: data.lead.id_local_for_marketplace },
					provinceDealership: null,
					useSameAddress: true,
					alternativeAddress: {
						province: undefined,
						town: "",
						address: "",
						CP: ""
					}
				}
			},
			insurance: {
				wants: undefined,
				company: insouranceOptions[0],
				financing: undefined,
				amount: SELL_CONTRACT_DEFAULT_PRICES.INSURANCE_AMOUNT
			}
		};

		const payAmountsDefault = {
			financingAmount: 0,
			dealershipTPVAmount: 0,
			virtualTPVAmount: 0,
			cashAmount: 0,
			transferenceAmount: 0,
			carPayAmount: 0
		};

		const defaultValues = {
			client: clientDefault,
			vehicle: vehicleDefault,
			sellInfo: sellInfoDefault,
			payAmounts: payAmountsDefault,
			observations: null
		};

		return defaultValues;
	};

	const createContract = async (formData) => {
		const clientData = {
			nombre: formData.client.name,
			apellido1: formData.client.firstSurname,
			apellido2: formData.client.secondSurname || null,
			telefono: formData.client.phone,
			email: formData.client.email,
			DNI: formData.client.dni,
			id_provincia: formData.client.province.value,
			provincia: formData.client.province.label,
			poblacion: formData.client.town,
			direccion: formData.client.address,
			CP: formData.client.cp,
			fecha_nacimiento: formData.client.dateOfBirth
		};

		const vehicleData = {
			matricula: formData.vehicle.plate,
			kilometros: formData.vehicle.km,
			bastidor: formData.vehicle.bastidor,
			id_local: formData.vehicle.dealership.value,
			local: formData.vehicle.dealership.label,
			id_provincia_local: formData.vehicle.dealershipProvince.value,
			provincia_local: formData.vehicle.dealershipProvince.label,
			aplica_iva: formData.vehicle.iva.value,
			fecha_ultima_itv: formData.vehicle.dateLastITV,
			fecha_caducidad_itv: formData.vehicle.dateExpirationITV,
			conformidades: formData.vehicle.conformidades || null
		};

		const sellData = {
			priceType: formData.sellInfo.priceType,
			coste_transferencia: formData.sellInfo.ownershipChangeAmount,
			id_gestoria: formData.sellInfo.gestoria.value,
			gestoria: formData.sellInfo.gestoria.label,
			agentDiscount: formData.sellInfo.courtesy ? formData.sellInfo.courtesy : 0,
			// Financing
			financia: formData.sellInfo.financing.wants.value,
			importe_financia: formData.sellInfo.financing.wants.value
				? formData.sellInfo.financing.discount
				: 0,
			entidad_financiera:
				formData.sellInfo.financing.wants.value && formData.sellInfo.financing.financialEntity
					? formData.sellInfo.financing.financialEntity.value
					: null,
			meses_financia:
				formData.sellInfo.financing.wants.value && formData.sellInfo.financing.financialEntity
					? "24 meses"
					: null,

			// Transport
			tipo_venta: formData.sellInfo.transport.sellType.value,
			entrega: formData.sellInfo.transport.delivery.type.value,
			transporte: formData.sellInfo.transport.wants.value,
			importe_transporte:
				formData.sellInfo.transport.wants.value === yesOrNotSelect[0].value &&
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.amount
					: 0,

			local_entrega_id:
				formData.sellInfo.transport.wants.value === yesOrNotSelect[0].value &&
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.dealership.value
					: null,
			local_entrega:
				formData.sellInfo.transport.wants.value === yesOrNotSelect[0].value &&
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.dealership.label
					: null,
			provincia_local_entrega_id:
				formData.sellInfo.transport.wants.value === yesOrNotSelect[0].value &&
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.provinceDealership.value
					: null,
			provincia_local_entrega:
				formData.sellInfo.transport.wants.value === yesOrNotSelect[0].value &&
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.provinceDealership.label
					: null,
			entrega_cambio: formData.sellInfo.transport?.delivery?.useSameAddress ?? true,
			// Insurance
			seguro_auto: formData.sellInfo.insurance.wants.value
		};

		if (formData.sellInfo.financing.wants.value) {
			sellData.TIN = formData.sellInfo.financing.TIN;
			sellData.term = formData.sellInfo.financing.term.value;
			sellData.quota = formData.sellInfo.financing.quota;
		}

		// Direccion alternativa
		if (!sellData.entrega_cambio) {
			sellData.provincia_entrega =
				formData.sellInfo.transport.delivery.alternativeAddress.province.label;
			sellData.poblacion_entrega = formData.sellInfo.transport.delivery.alternativeAddress.town;
			sellData.direccion_entrega = formData.sellInfo.transport.delivery.alternativeAddress.address;
			sellData.CP_entrega = formData.sellInfo.transport.delivery.alternativeAddress.CP;
		}

		if (formData.sellInfo.insurance.wants.value) {
			sellData.aseguradora_auto = formData.sellInfo.insurance.company.value;
			sellData.financia_seguro = formData.sellInfo.insurance.financing.value;
			sellData.importe_seguro = formData.sellInfo.insurance.amount;
		}

		const payAmountData = {
			pago_financiado: formData.payAmounts.financingAmount,
			pago_tpv_tienda: formData.payAmounts.dealershipTPVAmount,
			pago_tpv_pasarela: formData.payAmounts.virtualTPVAmount,
			pago_efectivo: formData.payAmounts.cashAmount,
			pago_transferencia: formData.payAmounts.transferenceAmount,
			pago_coche_forma_pago: formData.payAmounts.carPayAmount,
			total_pagos:
				formData.payAmounts.financingAmount +
				formData.payAmounts.dealershipTPVAmount +
				formData.payAmounts.virtualTPVAmount +
				formData.payAmounts.cashAmount +
				formData.payAmounts.transferenceAmount +
				formData.payAmounts.carPayAmount
		};

		const warrantyData = {
			typeId: formData.sellInfo.warranty.value,
			price: formData.sellInfo.warrantyAmount,
			iban_extra: formData.sellInfo.warrantyIBAN
		};

		const datos = {
			tipo: data.lead.carroceria,
			marca: data.lead.nombre_marca_v,
			modelo: data.lead.nombre_modelo_v,
			empresa_id: dealershipCompanie.value,
			...clientData,
			...vehicleData,
			...sellData,
			...payAmountData,
			observaciones: formData.observations || null
		};

		const payload = {
			agente: employeeId,
			vehiculo: data.reservation ? data.reservation.id_vehiculo : data.lead.vehicle_id,
			lead: data.reservation ? data.reservation.id_lead : data.lead.id_lead,
			cliente: data.reservation ? data.reservation.id_cliente : data.lead.id_cliente_lead,
			datos: datos,
			warranty: warrantyData
		};

		try {
			const response = await instance.post(API.CREATE_CONTRACT_VENTA, payload);
			onCreate(response.data.insertId);
			successToast("Contrato creado correctamente");
		} catch (err) {
			errorToast("Error al crear contrato");
		}
	};

	return (
		<SellContract
			mode="create"
			defaultValues={loadDefaultValues}
			onSubmit={createContract}
			gestoriasOptions={gestoriasOptions}
			provincesOptions={provincesOptions}
			dealershipsOptions={dealershipsOptions}
			warrantyOptions={warrantyOptions}
			flexicarVehicleId={data.lead.vehicleIdFlexicar}
		/>
	);
};

export default CreateSellContract;
