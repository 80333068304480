import { useEffect, useState, useContext } from "react";
import { useFormContext } from "react-hook-form";

import styles from "@styles/views/SellContract/sell-data.module.scss";

import {
	getFilterFuel,
	getFilterGVOBrands,
	getFilterGVOModels,
	getFilterTransmission
} from "@services/Filtros/filtros";

import PaneHeader from "@components/PaneHeader";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import FormDatePicker from "@components/Form/DatePicker/FormDatePicker";
import FormRadioButtonGroup from "@components/Form/RadioButtonGroup/FormRadioButtonGroup";

import {yesOrNotSelectPT } from "@constants/selectOptionsPT";
import { SELL_CONTRACT_PRICE_TYPES } from "@constants/sellContratDefaults";
import RolType, { IS_SUPER_ADMIN } from "@constants/roles";
import { UserContext } from "@contexts/UserContext";

const SellDataPT = ({ prices, carAsPaymentBrand, warrantyOptions }) => {
	const [modelDisabled, setModelDisabled] = useState(true);
	const [modelOptions, setModelOptions] = useState([]);
	const [brandFromData, setBrandFromData] = useState(undefined);

	const { rol, userType } = useContext(UserContext);

	const { watch, setValue } = useFormContext();

	const priceType = watch("sellInfo.priceType");

	const wantsTransport = watch("sellInfo.transport.wants");
	const wantsCarAsPayment = watch("sellInfo.carAsPayment.wants");
	const brandSelected = watch("sellInfo.carAsPayment.vehicle.brand");
	const kmsSelected = watch("sellInfo.carAsPayment.vehicle.kms");

	const warrantyPrice = watch("sellInfo.warranty.type")?.price;

	const allowedEditPrice = IS_SUPER_ADMIN(userType) || rol.includes(RolType.TERRITORIAL);

	const priceTypeOptions = prices.buyAndSell
		? [
			{
				label: "PVD",
				value: SELL_CONTRACT_PRICE_TYPES.PVD
			}
		]
		: [
			{
				label: "PVP Contado",
				value: SELL_CONTRACT_PRICE_TYPES.PVP_CONTADO
			},
			{
				label: "PVP Financiado",
				value: SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO
			}
		];

	useEffect(() => {
		setValue("sellInfo.warranty.amount", warrantyPrice);
	},[warrantyPrice]);

	useEffect(() => {
		const pvp = {
			[SELL_CONTRACT_PRICE_TYPES.PVP_CONTADO]: prices.priceCash,
			[SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO]: prices.priceOffer || prices.priceFinanced,
			[SELL_CONTRACT_PRICE_TYPES.PVD]: prices.priceDealerSellingOffer || prices.priceDealerSelling
		}[priceType];

		setValue("sellInfo.pvp", pvp);
	}, [setValue, priceType, prices]);

	useEffect(() => {
		if (wantsTransport?.value === yesOrNotSelectPT[1].value) {
			setValue("sellInfo.transport.amount", 0);
		}
	}, [setValue, wantsTransport?.value]);

	useEffect(() => {
		if (wantsCarAsPayment?.value === yesOrNotSelectPT[1].value) {
			setValue("sellInfo.carAsPayment.amount", 0);
		}
	}, [setValue, wantsCarAsPayment?.value]);

	useEffect(() => {
		setBrandFromData(carAsPaymentBrand);
	}, [carAsPaymentBrand]);

	useEffect(() => {
		if (brandSelected) {
			setModelDisabled(false);
			if (brandFromData !== brandSelected?.label) {
				setValue("sellInfo.carAsPayment.vehicle.model", null);
				setBrandFromData(undefined);
			}
			(async () => {
				const response = await getFilterGVOModels(brandSelected?.value);
				setModelOptions(response);
			})();
		} else {
			setModelDisabled(true);
		}
	}, [setValue, brandSelected, carAsPaymentBrand, brandFromData]);

	useEffect(() => {
		if (kmsSelected === 0) {
			setValue("sellInfo.carAsPayment.vehicle.kms", null);
		}
	}, [kmsSelected, setValue]);

	return (
		<div>
			<PaneHeader title="Dados da viatura" />
			<div className={styles.container}>
				<div className={styles.priceOptions}>
					<FormRadioButtonGroup
						name="sellInfo.priceType"
						options={priceTypeOptions}
					/>
				</div>
				<div className={styles.module}>
					<FormFormattedNumberInput
						name="sellInfo.pvp"
						label="Preço da viatura (PVP)"
						disabled
					/>
					<FormFormattedNumberInput
						name="sellInfo.administrativeCosts"
						label="Despesas administrativas"
					/>
					<FormDatePicker
						name="sellInfo.expectedDeliveryDate"
						label="Data Prevista de Entrega"
					/>
				</div>

				<div className={styles.module}>
					<FormFormattedNumberInput
						name="sellInfo.agentDiscount"
						label="Desconto"
					/>
				</div>

				<PaneHeader
					title="Transporte"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.transport.wants"
						label="Transporte"
						options={yesOrNotSelectPT}
					/>
					{wantsTransport?.value === yesOrNotSelectPT[0].value && (
						<FormFormattedNumberInput
							name="sellInfo.transport.amount"
							label="Quantia do transporte"
						/>
					)}
				</div>

				<PaneHeader
					title="Garantía"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.warranty.type"
						label="Tipo de Garantía"
						options={warrantyOptions}
					/>
					<FormFormattedNumberInput
						name="sellInfo.warranty.amount"
						label="Quantia da Garantía"
						disabled={!allowedEditPrice}
					/>
				</div>

				<PaneHeader
					title="Retoma"
					color="secondary"
				/>

				<div className={styles.module}>
					<FormSelect
						name="sellInfo.carAsPayment.wants"
						label="Retoma"
						options={yesOrNotSelectPT}
					/>
					{wantsCarAsPayment?.value === yesOrNotSelectPT[0].value && (
						<FormFormattedNumberInput
							name="sellInfo.carAsPayment.amount"
							label="Quantia da Retoma"
						/>
					)}
				</div>

				{wantsCarAsPayment?.value === yesOrNotSelectPT[0].value && (
					<div className={styles.module}>
						<FormSelect
							name="sellInfo.carAsPayment.vehicle.brand"
							label="Marca"
							loadOptions={getFilterGVOBrands}
						/>
						<FormSelect
							name="sellInfo.carAsPayment.vehicle.model"
							label="Modelo"
							options={modelOptions}
							isDisabled={modelDisabled}
						/>
						<FormInput
							name="sellInfo.carAsPayment.vehicle.version"
							label="Versão"
						/>
						<FormInput
							name="sellInfo.carAsPayment.vehicle.plate"
							label="Matrícula"
						/>
						<FormDatePicker
							name="sellInfo.carAsPayment.vehicle.plateDate"
							label="Data matrícula"
						/>
						<FormInput
							name="sellInfo.carAsPayment.vehicle.vin"
							label="Nº de chassis"
						/>
						<FormFormattedNumberInput
							name="sellInfo.carAsPayment.vehicle.kms"
							label="Quilómetros"
							suffix=" Km"
						/>
						<FormInput
							name="sellInfo.carAsPayment.vehicle.color"
							label="Cor"
							maxLength={15}
						/>
						<FormSelect
							name="sellInfo.carAsPayment.vehicle.fuel"
							label="Combustível"
							loadOptions={getFilterFuel}
						/>
						<FormSelect
							name="sellInfo.carAsPayment.vehicle.transmission"
							label="Transmissão"
							loadOptions={getFilterTransmission}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default SellDataPT;
